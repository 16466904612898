'use client';

import { Box } from '@mui/material';
import { FC, useEffect } from 'react';
import { NotificationResponseDtoTypeEnum } from 'modules/api/generated';
import { useNotificationCenter } from 'components/notificationCenter/hooks/useNotificationCenter';
import { NotificationModal } from 'components/notificationCenter/partials/notificationModal/NotificationModal';
import { NotificationTopBar } from 'components/notificationCenter/partials/notificationTopBar/NotificationTopBar';
import { usePathname } from 'next/navigation';

export const NotificationCenter: FC = () => {
    const { init, notification, close } = useNotificationCenter();
    const pathname = usePathname();

    useEffect(() => {
        init(pathname);
    }, [init, pathname]);

    if (!notification) {
        return null;
    }

    return (
        <Box id="notification-wrapper" width="100vw">
            {notification.type === NotificationResponseDtoTypeEnum.Bar && (
                <NotificationTopBar notification={notification} close={close} />
            )}
            {notification.type === NotificationResponseDtoTypeEnum.Modal && (
                <NotificationModal notification={notification} close={close} />
            )}
        </Box>
    );
};
