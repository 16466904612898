'use client';

import {FC} from 'react';
import useGdprCookieBar from 'modules/state/app/hook/gdpr/useCookieBar';
import useAnalyticsSender from 'modules/state/app/hook/analytics/useSender';
import Gemius from 'components/analytics/Gemius';
import FacebookPixel from 'components/analytics/FacebookPixel';
import GoogleTagManager from 'components/analytics/GoogleTagManager';
import Livemonitor from 'components/analytics/Livemonitor';
import RtbHouse from 'components/analytics/RtbHouse';

const Main: FC = () => {
    useAnalyticsSender();
    const { isAllowed: { googleTagManager, facebookPixel, rtbHouse, livemonitor } } = useGdprCookieBar();

    return <>
        {googleTagManager && <GoogleTagManager />}
        {facebookPixel && <FacebookPixel />}
        <Gemius /> {/* This is OK */}
        {rtbHouse && <RtbHouse />}
        {livemonitor && <Livemonitor />}
    </>;
};


export default Main;
