'use client';

import { FC, useEffect } from 'react';
import useDialog from 'modules/dialog/useDialog';
import { Theme, useMediaQuery } from '@mui/material';
import { ModalContent } from 'components/notificationCenter/partials/modalContent/ModalContent';
import { INotification } from 'components/notificationCenter/interfaces/notification/INotification';

interface Props {
    notification: INotification;
    close: () => void;
}

export const NotificationModal: FC<Props> = ({ notification, close }) => {
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    const handleClose = () => {
        hideDialog();

        close();
    };

    const {
        component: modal,
        show: showDialog,
        hide: hideDialog,
    } = useDialog({
        content: (
            <ModalContent notification={notification} onClose={handleClose} />
        ),
        paperProps: {
            sx: (theme) => ({
                maxWidth: 400,
                minWidth: { xs: 0, sm: 300 },
                mt: { xs: 0, sm: 10 },
                alignSelf: { xs: 'inherit', sm: 'flex-start' },
                borderRadius: theme.spacing(2),
                borderBottomLeftRadius: { xs: 0, sm: theme.spacing(2) },
                borderBottomRightRadius: { xs: 0, sm: theme.spacing(2) },
            }),
        },
        dialogContentProps: {
            sx: {
                p: { xs: 3, sm: 4 },
            },
        },
        wrapContentIntoBox: false,
        fullScreen: isMobile,
        fullWidth: isMobile,
        heightFitContent: isMobile,
        positionBottom: isMobile,
        closeOnBackdropClick: false,
    });

    useEffect(() => {
        if (notification) {
            showDialog();
        }
    }, [notification, showDialog]);

    return modal;
};
