const getCookie = (key: string): string | null => {
    return (
        document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)')?.pop() ||
        null
    );
};
const setCookie = (
    key: string,
    value: string,
    expirationDays: number
): void => {
    const expirationDate = new Date();
    expirationDate.setTime(
        expirationDate.getTime() + expirationDays * 24 * 60 * 60 * 1000
    );

    const expires = 'expires=' + expirationDate.toUTCString();

    document.cookie = key + '=' + value + '; ' + expires + '; path=/';
};

const generateTruthyBoolean = (probability: number): boolean => {
    return Math.random() * 100 <= probability;
};

export const assignAbTestingFlag = (
    cookieKey: string,
    probability: number,
    version: number,
    expirationDays: number
): void => {
    const flagRaw = getCookie(cookieKey);
    const newFlag = generateTruthyBoolean(probability)
        ? '0'
        : version.toString();

    if (flagRaw === null) {
        setCookie(cookieKey, newFlag, expirationDays);
        return;
    }

    const flag = parseInt(flagRaw, 10);

    if (flag === 0 || flag === version) {
        return;
    }

    setCookie(cookieKey, newFlag, expirationDays);
};
