import { FC, SVGProps } from 'react';
import { NotificationResponseDtoStyleEnum } from 'modules/api/generated';
import InfoColorIcon from 'modules/theme/components/icons/basic/InfoColor.svg';
import UpdateColorIcon from 'modules/theme/components/icons/basic/UpdateColor.svg';
import WarningColorIcon from 'modules/theme/components/icons/basic/WarningColor.svg';

export const NotificationStyleIconRecord: Record<
    NotificationResponseDtoStyleEnum,
    FC<SVGProps<SVGSVGElement>>
> = {
    [NotificationResponseDtoStyleEnum.Warning]: WarningColorIcon,
    [NotificationResponseDtoStyleEnum.Info]: InfoColorIcon,
    [NotificationResponseDtoStyleEnum.Update]: UpdateColorIcon,
};
