import { FC, useEffect } from 'react';
import { assignAbTestingFlag } from 'components/abTesting/util';

export const CookieSetter: FC = () => {
    useEffect(() => {
        // 5% pouzivatelov
        assignAbTestingFlag('next-enabled-listing', 5, 1, 365);
    }, []);

    return null;
};