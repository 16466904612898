'use client';

import { AxiosResponse } from 'axios';
import { useRecoilState } from 'recoil';
import {
    NotificationResponseDto,
    NotificationResponseDtoSectionEnum,
    NotificationResponseDtoTriggerEnum,
} from 'modules/api/generated';
import { setCookie } from 'modules/cookie/setCookie';
import { useState, useCallback, useEffect } from 'react';
import { notificationCenterApi } from 'modules/api/client';
import { appNotificationCenter } from 'modules/state/app/state';
import { INotification } from 'components/notificationCenter/interfaces/notification/INotification';
import { NOTIFICATION_CLOSED_COOKIE_PREFIX } from 'components/notificationCenter/constants/cookie/CookiePrefix';
import { NotificationStyleIconRecord } from 'components/notificationCenter/constants/record/icon/NotificationStyleIconRecord';

interface IReturn {
    notification: INotification | null;
    close: () => void;
    init: (pathname: string) => void;
}

export const useNotificationCenter = (): IReturn => {
    const [notification, setNotification] = useRecoilState(
        appNotificationCenter
    );

    const [cookieKey, setCookieKey] = useState<string | null>(null);

    const fetchNotification = useCallback(async (pathname: string) => {
        let response: AxiosResponse<NotificationResponseDto>;

        let section: NotificationResponseDtoSectionEnum;

        if (pathname === '/') {
            section = NotificationResponseDtoSectionEnum.Homepage;
        }

        if (pathname.startsWith('/vysledky')) {
            section = NotificationResponseDtoSectionEnum.SearchResults;
        }

        try {
            response = await notificationCenterApi.getNotification(section);
        } catch (error) {
            console.error(error);
        }

        if (!response?.data?.id) {
            return;
        }

        const notification = response.data;

        const cookieKey = `${NOTIFICATION_CLOSED_COOKIE_PREFIX}${notification.id}`;
        setCookieKey(cookieKey);

        setNotification({
            ...notification,
            icon: NotificationStyleIconRecord[notification.style],
        });
    }, [setNotification]);

    const handleSetClosedCookie = useCallback(() => {
        setCookie(cookieKey, '1', 60 * 60 * 24 * 365);
    }, [cookieKey]);

    const handleClose = useCallback(() => {
        handleSetClosedCookie();
        setNotification(null);
    }, [handleSetClosedCookie, setNotification]);

    useEffect(() => {
        if (
            notification?.trigger ===
            NotificationResponseDtoTriggerEnum.FirstVisitOnly
        ) {
            handleSetClosedCookie();
        }
    }, [notification, handleSetClosedCookie]);

    return {
        notification,
        close: handleClose,
        init: fetchNotification,
    };
};
