import { getEnviromentType } from 'modules/environment/getEnviromentType';
import { EType } from 'modules/environment/enums/EEnviromentType';

export const setCookie = (name: string, value: string, time: number): void => {
    if (getEnviromentType() === EType.BACK_END) return;

    let expires = '';
    if (time) {
        const date: Date = new Date();
        date.setTime(date.getTime() + (1000 * time));
        expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '')  + expires + '; path=/';
};